import {Routes} from "@angular/router";

import {AboutCompanyComponent} from "./about-company/about-company.component";
import {ContactComponent} from "./contact/contact.component";
import {HomeComponent} from "./home/home.component";
import {DownloadComponent} from "./download/download.component";
import {PageLayoutComponent} from "./page-layout/page-layout.component";
import {
    PrivacyPolicyComponent
} from "./privacy-policy/privacy-policy.component";
import {
    MobilePrivacyPolicyComponent
} from "./mobile-privacy-policy/mobile-privacy-policy.component";
import {ServiceTermsComponent} from "./service-terms/service-terms.component";

import {NotFoundComponent} from "./not-found/not-found.component";

export const routes: Routes = [
    {
	path: "",
	component: PageLayoutComponent,
	children: [
	    {path: "", component: HomeComponent},
	    {path: "app", redirectTo: "download"},
	    {path: "app.html", redirectTo: "download"},
	    {path: "about-us", component: AboutCompanyComponent},
	    {path: "about-us.html", redirectTo: "about-us"},
	    {path: "contact", component: ContactComponent},
	    {path: "download", component: DownloadComponent},
	    {path: "download.html", redirectTo: "download"},
	    {path: "privacy-policy", component: PrivacyPolicyComponent},
	    {path: "mobile-privacy-policy", component: MobilePrivacyPolicyComponent},
	    {path: "privacy-policy.html", redirectTo: "privacy-policy"},
	    {path: "mobile-privacy-policy.html", redirectTo: "mobile-privacy-policy"},
	    {path: "service-terms", component: ServiceTermsComponent},
      {path: "service-terms.html", redirectTo: "service-terms"},
      {path: "**", component: NotFoundComponent}
	]
    },
    {path: "index.html", redirectTo: ""},
];
