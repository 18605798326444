import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MobilePrivacyPolicyComponent } from './mobile-privacy-policy/mobile-privacy-policy.component';
import { ServiceTermsComponent } from './service-terms/service-terms.component';
import { DownloadComponent } from './download/download.component';
import { HomeComponent } from './home/home.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { ContactComponent } from './contact/contact.component';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { NotFoundComponent } from './not-found/not-found.component';


import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { I18nModule } from './i18n/i18n.module';
import { MetaService } from './MetaService';

@NgModule({
  declarations: [
    AppComponent,
    DownloadComponent,
    PrivacyPolicyComponent,
    MobilePrivacyPolicyComponent,
    ServiceTermsComponent,
    HomeComponent,
    PageLayoutComponent,
    ContactComponent,
    AboutCompanyComponent,
    NotFoundComponent,
  ],
  imports: [
    // TODO: how important is appId?
    BrowserModule.withServerTransition({appId: 'wayvpn-www'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    I18nModule
  ],
  providers: [MetaService],
  bootstrap: [AppComponent]
})

export class AppModule { }


