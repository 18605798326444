import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MetaService } from '../MetaService';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  hasWeb3Browser: boolean;
  hasFundsAvailable: boolean;
  hasAccountConfigured: boolean;

  constructor(

    private meta: MetaService,
    @Inject(LOCALE_ID) protected localeId: string
  ) { }

  ngOnInit() {

    this.meta.setGlobalTitle('WayVPN | Free VPN & Privacy Software');
    this.meta.setGlobalDescription('Your VPN should be secure, which is why wayvpn is building with source tools for custom VPN configurations and privacy services.');
    this.meta.update('og:url', 'https://www.wayvpn.com/');

    switch (this.localeId) {
      case 'ko':
        // define locale specific meta tags here
      break;
      case 'ja':
        // define locale specific meta tags here
      break;
      case 'zh':
        // define locale specific meta tags here
      break;
    }



  }

}
